import { useState } from "react";
import { errors } from "~/const";
import { kratos } from "~/services";
import { KratosClientError } from "~/services/kratos/errors";
import { useAsyncEffect } from "./async-effect";
import { useDateRefresh } from "./date-refresh";

type RecoveryFlow = Awaited<ReturnType<typeof kratos.initRecoveryFlow>>;

type Props = {
  returnTo?: string;
};

/**
 * Fetch a kratos recovery flow
 * @returns a recovery flow state used to recovery
 */
export const useRecoveryFlow = ({ returnTo }: Props): [RecoveryFlow | undefined, string | undefined] => {
  const [onRefresh, setRefreshDate] = useDateRefresh(kratos.refreshTimeInMs);
  const [state, setState] = useState<RecoveryFlow>();
  const [error, setError] = useState<string>();

  useAsyncEffect(async () => {
    const flow = await kratos
      .initRecoveryFlow({ returnTo })
      .catch((e) => setError(KratosClientError.fromResponse(e).formatMessage()));
    if (flow) {
      setState(flow);
      setRefreshDate(flow.expiresAt);
    }
  }, [onRefresh]);

  return [state, error];
};
