/* eslint-disable max-lines-per-function */
import React, { useRef, useState } from "react";
import { Anchor, Card, Error as ErrorMessage, Link, Warning } from "~/components/elements";
import { Logo, SocialButton } from "~/components/presets";
import { Error as ErrorLayout } from "~/components/layouts";
import { config, routes } from "~/const";
import { kratos } from "~/services";
import { useRegisterFlow, useStatus } from "~/hooks";
import { useStore } from "~/states";
import { Captcha, getCaptchaToken } from "~/components/helpers/captcha";
import { getValueFromSearch, submitWrapper } from "~/utils";
import { LoginForm, LoginFields } from "~/components/forms";
import * as Sentry from "@sentry/react";

import githubImgUrl from "@assets/logos/github.png?url";
import googleImgUrl from "@assets/logos/google.svg?url";
import microsoftImgUrl from "@assets/logos/microsoft.svg?url";
import linkedinImgUrl from "@assets/logos/linkedin.svg?url";
import { ReactComponent as Siemens } from "@assets/logos/Siemens.svg";
import { ReactComponent as EA } from "@assets/logos/EA.svg";
import { ReactComponent as NEC } from "@assets/logos/NEC.svg";
import { ReactComponent as ASUS } from "@assets/logos/ASUS.svg";
import { ReactComponent as Shell } from "@assets/logos/SHELL.svg";
import { ReactComponent as Zoom } from "@assets/logos/ZOOM.svg";

import { KratosClientError } from "~/services/kratos/errors";
import { analytics } from "~/segment";

const CAPTCHA_VERIFICATION_FAILED_HTTP_STATUS_CODE = 424;
const REGISTRATION_FAILED = "Registration Failed";
const METHOD_PASSWORD = "password";

export default () => {
  const returnTo = useStore((state) => state.returnTo);
  const [processing, setProcessing] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [flow, flowError] = useRegisterFlow({ returnTo, id: getValueFromSearch("flow") });
  const status = useStatus();

  const captchaRef = useRef<Captcha>(null);

  if (flowError) {
    return <ErrorLayout text={flowError} goBackUrl={window.location.pathname} />;
  }

  // Waits the flow state to be loaded before displaying anything
  if (!flow) return null;

  const submitWrapperProps = {
    setProcessing,
    processing,
    setError: status.setError,
    preSubmit: () => setShowSpinner(true),
    postSubmit: () => setShowSpinner(false),
  };

  const onSubmit = submitWrapper(submitWrapperProps, async (values: LoginFields) => {
    const captchaToken = await getCaptchaToken(captchaRef);

    const res = await kratos
      .register({
        ...values,
        ...flow,
        headers: {
          "x-captcha-token": captchaToken,
        },
      })
      .catch(async (e) => {
        // NOTE: Captcha verification happens on the server-side at Traefik which intercepts calls to Kratos and does
        // an HTTP request to an AWS Lambda set up for captcha verification. If the lambda returns an HTTP 200 OK
        // status code, it will let the request to Kratos pass, and if it returns an HTTP error status code Traefik
        // will abort the request and forward the lambda error response as-is to the client.
        if (e?.response?.status === CAPTCHA_VERIFICATION_FAILED_HTTP_STATUS_CODE) {
          Sentry.captureException(e, { extra: { method: METHOD_PASSWORD, reason: "captchaFailed", captchaToken } });
          await analytics.track(REGISTRATION_FAILED, {
            method: METHOD_PASSWORD,
            reason: "captcha_verification_failed",
          });
          throw new Error(
            "Sorry, we couldn't verify the authenticity of your registration at this time. Please try again in a few moments."
          );
        }

        if (KratosClientError.isKratosClientError(e)) {
          if (e.type === "ErrorValidationInvalidCredentials") {
            status.setWarning(e.message);
            return;
          }

          if (e.type === "ErrorValidationGeneric") {
            status.setWarning(e.message);
            return;
          }

          if (e.type === "ErrorValidationPasswordPolicyViolation") {
            status.setWarning(e.message);
            return;
          }

          if (e.type === "ErrorValidationDuplicateCredentials") {
            status.setWarning("An account with the same email exists already.");
            return;
          }

          await analytics.trackKratosError({ error: e, eventName: REGISTRATION_FAILED, method: METHOD_PASSWORD });
        } else {
          await analytics.track(REGISTRATION_FAILED, { method: METHOD_PASSWORD, reason: "unknown" });
        }

        throw e;
      });

    if (!res) {
      return;
    }

    if (!res?.active) {
      throw new Error("Sorry, a problem occurred and the registration status is invalid. Please try again.");
    }

    await analytics.track("Registration Succeeded", { method: "password" });

    window.location.assign(res.returnTo ?? config.defaultRedirect);
  });

  const onRegisterOidcWrapperProps = {
    ...submitWrapperProps,
    preSubmit: undefined,
    postSubmit: undefined,
  };

  const onRegisterOidc = submitWrapper(
    onRegisterOidcWrapperProps,
    async (provider: "google" | "github" | "microsoft" | "linkedin") => {
      const res = await kratos
        .registerOidc({
          ...flow,
          provider,
          headers: {
            "x-captcha-token": await getCaptchaToken(captchaRef),
          },
        })
        .catch(async (e) => {
          if (KratosClientError.isKratosClientError(e)) {
            await analytics.trackKratosError({ error: e, eventName: REGISTRATION_FAILED, method: provider });
          } else {
            Sentry.captureException(e, { extra: { method: provider, reason: e.type } });
            await analytics.track(REGISTRATION_FAILED, { method: provider, reason: e.type });
          }

          throw e;
        });

      window.location.assign(res.url);
    }
  );

  return (
    <div className="flex flex-row items-center w-full h-full">
      <div className="hidden lg:flex flex-col items-end flex-1 ">
        <div className="flex flex-col max-w-sm mr-16 gap-8">
          <Logo className="w-fit mb-2" height={32} />
          <div>
            <h2 className="text-2xl font-medium text-zinc-900 pb-2">1,000 free messages every month</h2>
            <p className="text-sm text-zinc-700">
              1,000 incoming messages included every month means Botpress is free for most users.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-medium text-zinc-900 pb-2">Your own ChatGPT-like chatbot</h2>
            <p className="text-sm text-zinc-700">
              Leverage the latest in LLMs & GPT with the consistency your project can rely on.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-medium text-zinc-900 pb-2">From URL to chatbot in minutes</h2>
            <p className="text-sm text-zinc-700">
              Instantly bring your chatbot to life with our generative AI building companion.
            </p>
          </div>
          <div className="flex flex-col gap-6 mt-8">
            <p className="text-sm text-zinc-500 font-medium">Trusted by thousands of organizations</p>
            <div className="flex flex-row gap-3">
              <Siemens />
              <NEC />
              <Shell />
            </div>
            <div className="flex flex-row gap-3">
              <ASUS />
              <Zoom />
              <EA />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center lg:items-start flex-1">
        <div className="lg:ml-16">
          <Captcha ref={captchaRef} />
          <h2 className="font-medium text-3xl mb-4">
            <span className="">Get Started</span>
          </h2>
          <div className="flex flex-col w-full max-w-xs gap-3">
            <SocialButton
              onClick={() => onRegisterOidc("github")}
              icon={githubImgUrl}
              variant="zinc"
              text="Sign up with GitHub"
            />
            <SocialButton
              onClick={() => onRegisterOidc("google")}
              icon={googleImgUrl}
              variant="blue"
              text="Sign up with Google"
            />
            <SocialButton
              onClick={() => onRegisterOidc("microsoft")}
              icon={microsoftImgUrl}
              variant="blue"
              text="Sign up with Microsoft"
            />
            <SocialButton
              onClick={() => onRegisterOidc("linkedin")}
              icon={linkedinImgUrl}
              variant="blue"
              text="Sign up with LinkedIn"
            />

            <hr className="border-t my-4" />
            <LoginForm
              showForgotPassword={false}
              onSubmit={onSubmit}
              formType="register"
              disableSubmit={processing}
              showSpinner={showSpinner}
            />

            {status.error && <ErrorMessage>{status.error}</ErrorMessage>}
            {status.warning && <Warning>{status.warning}</Warning>}

            <p className="text-xs mb-8 text-zinc-700">
              Already have an account ? <Link to={routes.login}>Login</Link>
            </p>
            <p className="text-xs text-zinc-700">
              By signing up, you agree to our{" "}
              <Anchor href="https://botpress.com/company/terms" target="_blank">
                Terms &amp; Conditions
              </Anchor>{" "}
              and our{" "}
              <Anchor href="https://botpress.com/privacy" target="_blank">
                Privacy policy
              </Anchor>
              .
            </p>
            <Logo className=" lg:hidden w-fit mb-2 mt-16 self-center" height={24} />
          </div>
        </div>
      </div>
    </div>
  );
};
