const getDomain = (hostname: string) => {
  const hostnameParts = hostname.split(".");

  if (hostnameParts.length < 2) {
    return hostname;
  }

  return `${hostnameParts[hostnameParts.length - 2]}.${hostnameParts[hostnameParts.length - 1]}`;
};

export const errors = {
  default:
    "Oops! Something went wrong. We're sorry, but we could not log you in at this time. Please try again later or contact support for assistance.",
  title: "Login Error",
} as const;

const ROUTES = [
  {
    name: "Login",
    path: "/login",
    id: "login",
  },
  { name: "Registration", path: "/registration", id: "registration" },
  { name: "Registration Google", path: "/registration/google", id: "registration-google" },
  { name: "Recovery", path: "/recovery", id: "recovery" },
  { name: "Verification", path: "/verification", id: "verification" },
  { name: "Logout", path: "/logout", id: "logout" },
  { name: "Settings", path: "/settings", id: "settings" },
  { name: "Link", path: "/link", id: "link" },
  { name: "Error", path: "/error", id: "error" },
] as const;

type RouteIdToPath = { [Route in typeof ROUTES[number] as Route["id"]]: Route["path"] };
type RoutePathToName = { [Route in typeof ROUTES[number] as Route["path"]]: Route["name"] };
type Path = typeof ROUTES[number]["path"];

export const routes: RouteIdToPath = {
  login: "/login",
  registration: "/registration",
  recovery: "/recovery",
  verification: "/verification",
  logout: "/logout",
  settings: "/settings",
  link: "/link",
  error: "/error",
  "registration-google": "/registration/google",
} as const;

export const namesByPath: RoutePathToName = {
  "/login": "Login",
  "/registration": "Registration",
  "/recovery": "Recovery",
  "/verification": "Verification",
  "/logout": "Logout",
  "/settings": "Settings",
  "/link": "Link",
  "/error": "Error",
  "/registration/google": "Registration Google",
} as const;

export const isKnownPath = (path: string): path is Path => path in namesByPath;

export const config = {
  kratosUrl: import.meta.env.VITE_KRATOS_URL ?? `https://users.${getDomain(location.hostname)}`,
  defaultRedirect: import.meta.env.VITE_DEFAULT_REDIRECT ?? `https://app.${getDomain(location.hostname)}`,
  privilegeSessionMaxAge: import.meta.env.VITE_PRIVILEGE_SESSION_MAX_AGE_MS
    ? parseInt(import.meta.env.VITE_PRIVILEGE_SESSION_MAX_AGE_MS, 10)
    : 15 * 60 * 1000, // default to 15 minutes
  captchaLambdaUrl: import.meta.env.VITE_RECAPTCHA_LAMBDA_URL ?? "http://localhost:9873",
  captchaSiteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
  segmentWriteKey: import.meta.env.VITE_SEGMENT_WRITE_KEY,
  userStoreProxyUrl: `https://users.${getDomain(location.hostname)}`,
} as const;

export const queryKeys = {
  auth: ["auth"] as const,
};
