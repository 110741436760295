import { useState } from "react";
import { kratos } from "~/services";
import { KratosClientError } from "~/services/kratos/errors";
import { useAsyncEffect } from "./async-effect";
import { useDateRefresh } from "./date-refresh";

type LoginFlow = Awaited<ReturnType<typeof kratos.initLoginFlow>>;

type Props = {
  returnTo?: string;
  refresh: boolean;
  id?: string;
};

/**
 * Fetch a kratos login flow
 * @returns a login flow state used to login
 */
export const useLoginFlow = ({ returnTo, refresh, id }: Props): [LoginFlow | undefined, string | undefined] => {
  const [onRefresh, setRefreshDate] = useDateRefresh(kratos.refreshTimeInMs);
  const [state, setState] = useState<LoginFlow>();
  const [error, setError] = useState<string>();

  useAsyncEffect(async () => {
    let flow: LoginFlow | void;

    if (!state && id) {
      flow = await kratos
        .getLoginFlow({ id })
        .catch((e) => setError(KratosClientError.fromResponse(e).formatMessage()));
    } else {
      flow = await kratos
        .initLoginFlow({ returnTo, refresh })
        .catch((e) => setError(KratosClientError.fromResponse(e).formatMessage()));
    }

    if (flow) {
      setState(flow);
      setRefreshDate(flow.expiresAt);
    }
  }, [onRefresh]);

  return [state, error];
};
